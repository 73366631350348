<template>
  <div>
    <div id="nav" class="fixed z-30">
      <div class="w-screen menu-bar rounded-b-2xl transition-all ease-in-out duration-300 overflow-hidden relative z-10 px-8">
        <div class="container relative z-20 py-8 lg:py-44 relative flex justify-between items-center">
          <div class="relative mb-0">
            <slot name="brand"/>
          </div>
          <div class="hidden lg:block">
            <slot name="mainmenu"/>
          </div>
          <div class="h-8 lg:hidden gap-x-6">
            <button name="main menu" aria-label="main menu" type="button" @click="addo();"
                    class="text-center text-primary toggle-me font-lato text-sm uppercase tracking-widest	">
              <div id="nav-icon4" :class="{ open: isActive }">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="transition-all ease-in-out duration-500 min-h-screen menu-off fixed z-10">
      <div style="filter: blur(2px); backdrop-filter: blur(10px);" class="absolute top-0 left-0 bottom-0 right-0">

      </div>
      <div class="w-screen px-8 relative overflow-hidden rounded-b-xl h-9/12 py-120 bg-white">
        <img src="/app/themes/wordpress/assets/images/shape-one.svg" alt="top-shape" class="absolute bottom-0 right-0">
        <img src="/app/themes/wordpress/assets/images/shape-2.svg" alt="top-shape" class="absolute bottom-0 right-0">
        <div>
          <slot name="mainmenu"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
      isOpenLeft: false,
      services: false,
      isActive: false,
      isSlid: true,
      isMenu: false,
    }
  },
  methods: {
    addo() {
      document.querySelector('.menu-off').classList.toggle('menu-on')
    },
  }
}
</script>
