<template>
  <div v-swiper:ProjectSlider="settings">
    <div class="swiper-wrapper">
      <slot />
    </div>
    <div class="swiper-scrollbar mt-8">
      <div class="line h-px bg-black" style="margin-bottom:-3.5px;"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProjectSlider',
  data () {
    return {
      settings: {
        slidesPerView: 1,
        direction: "horizontal",
        loop: false,
        spaceBetween: 40,
        noSwiping: false,
        autoplay: {
          delay: 3000,
        },
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true,
          dragSize: 100.
        },
        allowTouchMove: true,
        breakpoints: {
          650: {
            slidesPerView: 2,
            spaceBetween: 24,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 45,
          },
        }
      }
    }
  }
}
</script>
