import Vue from 'vue'
window.Vue = Vue
import VueAnimate from 'vue-animate-scroll'
Vue.use(VueAnimate)
import $ from "jquery";
import 'slick-carousel'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)

import AppNavBar from './components/AppNavBar'
import WorkWithSlider from './components/WorkWithSlider'
import Modal from './components/Modal'
import VideoSlider from './components/VideoSlider'
Vue.component('VideoSlider', VideoSlider)
Vue.component('Modal', Modal)
Vue.component('AppNavBar', AppNavBar)
Vue.component('WorkWithSlider', WorkWithSlider)


const files = require.context('./components/icons/', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
$(document).ready(function(){
  $(".down-arrow-wrapper").on("click", function() {
    window.scrollTo({ top: 550, behavior: 'smooth' })
  });

    var numbs = [0, 1, 2, 3, 4];
  var idx = Math.floor(Math.random() * numbs.length);
  $('.slider').slick({
    fade: true,
    dots: true,
    customPaging : function(slick, i) {
      var slidernav = $(slick.$slides[i]).data('slidernav');
      console.log(slidernav);
      return '<a>'+ slidernav +'</a>';
    },
    infinite: true,
    speed: 500,
    rows: 0,
    slidesToShow: 1,
    initialSlide: idx,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          dots: false,
        }
      }
    ]
  });

  // On slide chnage, play a video inside the current slide
  $('.slider').on('afterChange', function(event, slick, currentSlide){

    var slide = $(slick.$slides.get(currentSlide)),
        pastSlide = $(slick.$slides.get(currentSlide - 1)),
        pastV = pastSlide.find('video'),
        v = slide.find('video');

    if (v.length > 0) {
      v[0].play();
    }
    if (pastV.length > 0) {
      pastV[0].pause();
      pastV[0].currentTime = 0;
    }

  });
  var carouselPMenu = $('.slider'),
  carouselP = carouselPMenu.find('.slick-slide');

  if (carouselP.first('.slick-slide').find('video').length > 0) {
    $('.slick-current').find('video')[0].play();
  }

  $('#nav-icon4').click(function(){
    $(this).toggleClass('open');
  });
  $('.accordion-list > li > .answer').hide();

  $('.accordion-list > li > .clickable').click(function() {
    if($(this).parents('li').hasClass("active")){
      $(this).parents('li').removeClass("active").find(".answer").slideUp();
    } else {
      $(".accordion-list > li.active .answer").slideUp();
      $(".accordion-list > li.active").removeClass("active");
      $(this).parent().addClass("active").find(".answer").slideDown();
    }
    return false;
  });

  if (window.location.hash) {
    var hash = window.location.hash;

    if ($(hash).length) {
      $('html, body').animate({
        scrollTop: $(hash).offset().top -150
      }, 500,);
    }
  }


});

