<template>
  <div v-swiper:swiper="settings" ref="videoSwiper" @slide-change="changeSlideFunc">
    <div class="swiper-wrapper">
      <slot/>
    </div>
    <div class="relative text-left lg:text-center text-14 font-medium -top-20 video-pagination swiper-pagination"></div>
  </div>
</template>
<script>
export default {
  name: 'VideoSlider',
  props: {
    menu: {
      type: Array,
      required: true,
    }
  },
  created() {
    let randomSlide = Math.floor(Math.random() * (this.$props.menu.length - 1));
    let video = document.querySelector(`video#video-${randomSlide}`);
    if (!video) {
      // Loop the defaults on mobile since there are no videos
      this.settings.loop = true;
      this.settings.autoplay = {
        delay: 6000,
        disableOnInteraction: false,
      }
    }

    // Randomise
    this.settings.initialSlide = randomSlide;
  },
  mounted() {
    this.registerVideoEvents();
  },
  methods: {
    changeSlideFunc: function () {
      let index = (this.swiper.realIndex);
      let video = document.querySelector(`video#video-${index}`);
      if (video && video.currentTime > 0) {
        video.currentTime = 0;
        video.play();
      }
    },
    registerVideoEvents: function () {
      let videos = document.querySelectorAll('.swiper-slide video');
      for (let i = 0; i < videos.length; i++) {
        videos[i].addEventListener('timeupdate', this.videoTiming);
      }
    },
    videoTiming: function (event) {
      if (event.target.id === `video-${this.swiper.realIndex}` && event.target.currentTime >= 5.0) {
        this.videoEnded(event);
      }
    },
    videoEnded: function (event) {
      if (event.target.id === `video-${this.swiper.realIndex}`) {
        if (this.swiper.realIndex === (this.$props.menu.length - 1)) {
          this.swiper.slideTo(0);
        } else {
          this.changeSlideFunc();
          this.swiper.slideNext();
        }
      }
    }
  },
  data() {
    return {
      settings: {
        effect: 'fade',
        slidesPerView: 1,
        loop: false,
        speed: 500,
        autoplay: false,
        pagination: {
          el: '.video-pagination.swiper-pagination',
          clickable: true,
          renderBullet: ((index, className) => {
            return '<span class="' + className + '">' + (this.$props.menu[index]) + ' <span class="side-arrow-wrapper"><span class="side-arrow"> >> </span></span></span>'
          }),
        },
        allowTouchMove: true,
      }
    };
  },
}
</script>
